import { DurationUnitType } from "dayjs/plugin/duration"

import { PlanCode, PricingPlanTypes, PricingProductEnum } from "../types"
import { usePricingStore } from "./store/usePricingStore"

export const usePricingPlanHelpers = (plan: MaybeRef<PricingPlanWithDetails>) => {
  const { isPlanOnWaitingList } = useUserWaitingList()
  const { i18n } = useI18nUtils()
  const planAsRef = toRef(plan)

  const isConsideredManagedPlan = computed(() => {
    return [PricingPlanTypes.PlanCode.MANAGED].includes(planAsRef.value.code)
  })

  const isFreePlan = computed(() => {
    return (
      !planAsRef.value.pricing.price &&
      planAsRef.value?.pricing?.type === PricingPlanTypes.PlanPeriodType.FREE
    )
  })

  const isUserWaitingListed = computed(() => isPlanOnWaitingList(plan).value)

  const isAnUpcomingPlan = computed(() => {
    return planAsRef.value?.status === PricingPlanTypes.PlanStatus.UPCOMING
  })

  const isPlanHasTrialPeriod = computed(() => {
    return !!planAsRef.value?.pricing?.trialPeriod
  })

  const trialPeriodDurationText = computed(() => {
    const dayjs = useDayjs()

    if (!isPlanHasTrialPeriod.value) {
      return ""
    }

    const trialPeriodAmount = planAsRef.value?.pricing?.trialPeriod?.split("_")[0]
    const trialPeriodUnit = planAsRef.value?.pricing?.trialPeriod?.split("_")[1]
    const trialDurationText = dayjs
      .duration(Number(trialPeriodAmount), trialPeriodUnit as DurationUnitType)
      .humanize()

    return trialDurationText
  })

  return {
    isConsideredManagedPlan,
    isUserWaitingListed,
    isAnUpcomingPlan,
    isFreePlan,
    isPlanHasTrialPeriod,
    trialPeriodDurationText,
  }
}

export const usePricingPlanHelperById = (productId: MaybeRef<PricingProductEnum>, planCode: PlanCode) => {
  productId = toRef(productId)
  const { getProductPlanById } = usePricingStore()

  const plan = computed(() => getProductPlanById(productId.value, planCode))
  const helpers = usePricingPlanHelpers(plan)

  return { ...helpers, plan }
}
